<template>
	<div class="dashboard-page row-space-tbf">
		<div class="space-left"></div>
		<div class="content" v-if="loadedApplications">
			<div class="apps-row">
				<!-- Raport responsabilitati -->
				<div class="app-item">
					<template v-if="soft_organigram.is_active">
						<!-- responsabilitati -->
						<div class="app-box organigram-box" v-if="loaded">
							<div class="toggle-type">
								<div class="type" @click="reportResponsabilities = true" :class="{ selected: reportResponsabilities == true }">
									{{ $t("dashboard.responsibilities") }}
								</div>
								<div class="type" @click="reportResponsabilities = false" :class="{ selected: reportResponsabilities == false }">
									{{ $t("dashboard.tasks") }}
								</div>
								<div class="sub-description">{{ $t("dashboard.last_30_days") }}</div>
							</div>
							<div class="data">
								<div class="title">
									{{ reportResponsabilities ? $t("dashboard.report_responsibilities") : $t("dashboard.report_tasks") }}
								</div>
								<template v-if="reportResponsabilities">
									<div class="chart-values">
										<div class="chart-value">
											<div class="color-square green"></div>
											<span>{{ $t("dashboard.responsibility_accomplished", { n: responsibilities.last_month_responsibilities.accomplished }) }}</span>
										</div>
										<div class="chart-value">
											<div class="color-square red"></div>
											<span>{{ $t("dashboard.responsibility_unaccomplished", { n: responsibilities.last_month_responsibilities.unaccomplished }) }}</span>
										</div>
										<div class="chart-value">
											<div class="color-square blue"></div>
											<span>{{ $t("dashboard.responsibility_unevaluated", { n: responsibilities.last_month_responsibilities.unevaluated }) }}</span>
										</div>
										<div class="chart-value">
											<div class="color-square yellow"></div>
											<span>{{ $t("dashboard.responsibility_free_day", { n: responsibilities.last_month_responsibilities.free_day }) }}</span>
										</div>
									</div>
								</template>
								<template v-else>
									<div class="chart-values">
										<div class="chart-value">
											<div class="color-square yellow"></div>
											<span>{{ $t("dashboard.task_future_unaccomplished", { n: reportTasks.future_unaccomplished }) }}</span>
										</div>
										<div class="chart-value">
											<div class="color-square red"></div>
											<span>{{ $t("dashboard.task_past_unaccomplished", { n: reportTasks.past_unaccomplished }) }}</span>
										</div>
										<div class="chart-value">
											<div class="color-square green"></div>
											<span>{{ $t("dashboard.task_accomplished_before_deadline", { n: reportTasks.accomplished_before_deadline }) }}</span>
										</div>
										<div class="chart-value">
											<div class="color-square blue"></div>
											<span>{{ $t("dashboard.task_accomplished_after_deadline", { n: reportTasks.accomplished_after_deadline }) }}</span>
										</div>
										<div class="chart-value">
											<div class="color-square grey"></div>
											<span>{{ $t("dashboard.task_without_deadline", { n: reportTasks.without_deadline }) }}</span>
										</div>
									</div>
								</template>
							</div>
							<div class="action" v-if="reportResponsabilities">
								<vc-donut background="#ebfbff" foreground="#FCFCFC" :size="50" unit="px" :thickness="25" :sections="sections" :total="totalSections" :start-angle="0" />
							</div>
							<div class="action" v-else>
								<vc-donut
									background="#ebfbff"
									foreground="#FCFCFC"
									:size="50"
									unit="px"
									:thickness="25"
									:sections="taskSections"
									:total="totalTaskSections"
									:start-angle="0"
									:key="`donut-report-task-${donutReportTaskKey}`"
								/>
							</div>
						</div>
						<div class="app-box placeholder-loader" v-else></div>
					</template>
					<div class="app-box procedure-box unowned" v-else>
						<div class="data">
							<div class="title">{{ $t("dashboard.tbf_organigram") }}</div>
							<div class="subtitle">
								<button class="link" @click="openModalSubscription">{{ $t("dashboard.activate_now") }}</button>
							</div>
						</div>
						<div class="action" v-if="$resize && $mq.above(431)">
							<button class="link-icon" @click="openModalSubscription">
								<icon-organigram class="icon organigram-icon" />
							</button>
						</div>
					</div>
				</div>
				<!-- Proceduri in derulare -->
				<div class="app-item">
					<template v-if="soft_procedures.is_active">
						<div class="app-box procedure-box" v-if="loadedProcedures" :class="{ unowned: !soft_procedures.is_active }">
							<div class="data">
								<div class="title">{{ $t("dashboard.procedures_in_progress", { n: hasProcedures }) }}</div>
								<div class="subtitle link" @click="$auth.ready() ? showModal('procedure_check', { userSlug: $auth.user().slug }) : ''">
									{{ !soft_procedures.is_active ? $t("dashboard.activate_now") : $t("dashboard.see_procedures") }}
								</div>
							</div>
							<div class="action">
								<div class="btn-play" @click="$auth.ready() ? showModal('procedure_check', { userSlug: $auth.user().slug }) : ''">
									<icon-learn class="icon play-icon" />
								</div>
							</div>
						</div>
						<div class="app-box placeholder-loader" v-else></div>
					</template>
					<div class="app-box procedure-box unowned" v-else>
						<div class="data">
							<div class="title">{{ $t("dashboard.tbf_procedures") }}</div>
							<div class="subtitle">
								<button class="link" @click="openModalSubscription">{{ $t("dashboard.activate_now") }}</button>
							</div>
						</div>
						<div class="action" v-if="$resize && $mq.above(431)">
							<button class="btn-play" @click="openModalSubscription">
								<icon-learn class="icon play-icon" />
							</button>
						</div>
					</div>
				</div>
				<!-- Promisiunea zilei -->
				<div class="app-item">
					<template v-if="soft_objectives.is_active">
						<template v-if="loadedObjectives">
							<template v-if="objectives.key_results_count > 0">
								<div class="app-box objective-box unowned" v-if="objectives.daily_promise_status == 'promise_time_passed'">
									<div class="data">
										<div class="title">{{ $t("dashboard.promise_time_passed") }}</div>
										<div class="subtitle">{{ $t("dashboard.promise_time_passed_desc", { n: objectives.promise_time }) }}</div>
									</div>
									<div class="action" v-if="$resize && $mq.above(431)">
										<icon-objectives class="icon objective-icon" />
									</div>
								</div>

								<div class="app-box objective-box" v-else-if="objectives.daily_promise_status != 'person_not_working_today'">
									<div class="data">
										<div class="title">
											{{ $t("dashboard.has_promise") }} {{ objectives.daily_promise_status == "promise_not_set" ? $t("dashboard.unstable") : "" }}
											<template v-if="objectives.daily_promise_status == 'has_promise'">
												<div class="description" v-html="objectives.daily_promise.name"></div>
												<div class="sub-description">
													{{ $t("dashboard.contribute-to") }}: {{ $tc("dashboard.count_key_results_involved", objectives.daily_promise.key_results_involved) }}
												</div>
											</template>
										</div>

										<div class="subtitle">
											<a href="https://obiective.tbf.ro/obiective" target="_blank">{{ $t("dashboard.key_results_update", { n: objectives.require_update_key_results }) }}</a>
										</div>

										<div v-if="$resize && $mq.below(1300)" class="add-promise-btn">
											<template v-if="objectives.daily_promise_status == 'has_promise'">
												<button
													class="btn-tbf blue center"
													@click="showModal('evaluate_promise', { promise: objectives.daily_promise })"
													v-if="objectives.daily_promise.status == 'pending'"
												>
													<div class="text">{{ $t("dashboard.evaluate_promise") }}</div>
												</button>
											</template>
											<template v-else>
												<button class="btn-tbf blue center" @click="showModal('add_promise')">
													<div class="text">{{ $t("dashboard.add_promise") }}</div>
												</button>
											</template>
										</div>
									</div>
									<div class="action" v-if="$resize && $mq.above(1301)">
										<template v-if="objectives.daily_promise_status == 'has_promise'">
											<button
												class="btn-tbf blue center"
												@click="showModal('evaluate_promise', { promise: objectives.daily_promise })"
												v-if="objectives.daily_promise.status == 'pending'"
											>
												<div class="text">{{ $t("dashboard.evaluate_promise") }}</div>
											</button>
											<icon-x-circle class="icon red-icon" v-else-if="objectives.daily_promise.status == 'not_completed'" />
											<icon-check-circle class="icon play-icon" v-else-if="objectives.daily_promise.status == 'completed'" />
										</template>
										<template v-else>
											<button class="btn-tbf blue center" @click="showModal('add_promise')">
												<div class="text">{{ $t("dashboard.add_promise") }}</div>
											</button>
										</template>
									</div>
								</div>

								<div class="app-box objective-box" v-else>
									<div class="data">
										<div class="title">{{ $t("dashboard.has_promise") }} {{ objectives.daily_promise_status ? "" : $t("dashboard.unstable") }}</div>
										<div class="subtitle">{{ $t("dashboard.free_day") }}</div>
									</div>
									<div class="action" v-if="$resize && $mq.above(431)">
										<icon-objectives class="icon objective-icon" />
									</div>
								</div>
							</template>

							<div class="app-box objective-box" v-else>
								<div class="data">
									<div class="title">{{ $t("dashboard.has_promise") }}</div>
									<div class="subtitle">{{ $t("dashboard.promise_not_key_results") }}</div>
								</div>
								<div class="action" v-if="$resize && $mq.above(431)">
									<icon-objectives class="icon objective-icon" />
								</div>
							</div>
						</template>
						<div class="app-box placeholder-loader" v-else></div>
					</template>
					<div class="app-box objective-box unowned" v-else>
						<div class="data">
							<div class="title">{{ $t("dashboard.tbf_objectives") }}</div>
							<div class="subtitle">
								<button class="link" @click="openModalSubscription">{{ $t("dashboard.activate_now") }}</button>
							</div>
						</div>
						<div class="action" v-if="$resize && $mq.above(431)" @click="openModalSubscription">
							<icon-objectives class="icon objective-icon" />
						</div>
					</div>
				</div>
			</div>

			<div class="responsibilities-row" v-if="soft_organigram.is_active || soft_objectives.is_active">
				<div class="header-section">
					<div class="title-section">{{ $t("dashboard.work_calendar") }}</div>
				</div>

				<div class="tasks-container" :class="{ mobile_width: $resize && $mq.below(649) }">
					<div class="header-filters">
						<div class="filters-view">
							<div class="period-filters" v-if="selectedViewType == 'split'">
								<div class="actions-group">
									<button class="btn-group-action" @click="prevPeriod()"><icon-left class="icon" /></button>
									<button class="btn-group-action" @click="goToToday()">{{ $t("calendar.today") }}</button>
									<button class="btn-group-action" @click="nextPeriod()"><icon-right class="icon" /></button>
								</div>
								<div class="actions-group mg-left">
									<button class="btn-group-action" @click="changeView('monthly')" :class="{ active: timeRange == 'monthly' }">{{ $t("dashboard.monthly") }}</button>
									<button class="btn-group-action" @click="changeView('weekly')" :class="{ active: timeRange == 'weekly' }">{{ $t("dashboard.weekly") }}</button>
									<button class="btn-group-action" @click="changeView('daily')" :class="{ active: timeRange == 'daily' }">{{ $t("dashboard.daily") }}</button>
								</div>
								<div class="period">
									{{ calendarPeriod }}
								</div>
							</div>

							<div v-else class="period-filters">
								<date-range-picker
									ref="picker"
									class="calendar-filter list-filter"
									:class="[{ mobile: $resize && $mq.below(1000) }]"
									opens="right"
									:locale-data="calendarFilterSettings"
									:singleDatePicker="$mq.below(650) ? 'range' : false"
									:showWeekNumbers="false"
									:showDropdowns="false"
									:ranges="false"
									:autoApply="true"
									:linkedCalendars="true"
									v-model="dateRange"
								>
									<template v-slot:input="picker">
										<div class="input-picker">
											<div class="icon"><icon-date /></div>
											<div class="date" v-if="picker.startDate == picker.endDate">{{ picker.startDate | moment("DD MMM YY") }}</div>
											<div class="date" v-else>{{ picker.startDate | moment("DD MMM YY") }} - {{ picker.endDate | moment("DD MMM YY") }}</div>
											<icon-arrow class="icon-arrow right" />
										</div>
									</template>
								</date-range-picker>
							</div>
							<!-- actiuni dreapta -->
						</div>

						<div class="filters-db" :class="{ 'without-task': !soft_organigram.is_active }">
							<div class="actions-group">
								<button @click="changeListType('list')" class="btn-group-action" :class="{ active: selectedViewType == 'list' }">
									<span v-if="$resize && $mq.above(1720)">{{ $t("dashboard.list") }}</span>
									<div class="icon" v-else><icon-list /></div>
								</button>
								<button @click="changeListType('split')" class="btn-group-action" :class="{ active: selectedViewType == 'split' }">
									<span v-if="$resize && $mq.above(1720)">{{ $t("dashboard.calendar") }}</span>
									<div class="icon" v-else><icon-calendar /></div>
								</button>
							</div>

							<div class="actions-group">
								<button @click="changeListType('personal')" class="btn-group-action" :class="{ active: selectedOptionsSource == 'personal' }">
									<span v-if="$resize && $mq.above(1660)">{{ $t("dashboard.personal") }}</span>
									<div class="icon" v-else><icon-user /></div>
								</button>
								<button @click="changeListType('all')" class="btn-group-action" :class="{ active: selectedOptionsSource == '' }">
									<span v-if="$resize && $mq.above(1660)">{{ $t("dashboard.all") }}</span>
									<div class="icon" v-else><icon-people /></div>
								</button>
							</div>

							<div class="break" v-if="$resize && $mq.below(649)"></div>

							<div class="filter-dropdown-header filter-dashboard filter-activities dropdown">
								<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text" v-if="!selectedFiltersDashboard.general.length">{{ $t("filters.by_dashboard") }}</span>
									<span class="text" v-else>{{ selectedFiltersDashboard.general.length }} {{ $tc("filters.dashboard_selected", selectedFiltersDashboard.general.length) }}</span>

									<div class="icon-filter" v-if="!selectedFiltersDashboard.general.length"><icon-arrow /></div>
									<div class="icon-clear" @click.stop="clearFilter('selectedFiltersDashboard')" v-else><icon-close /></div>
								</button>

								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<div class="search-filter-dropdown">
										<input type="text" v-model="searchFilterDashboard" :placeholder="$t('general.search_by_name')" />
									</div>

									<template v-for="(filterItem, index) in filteredDashboard" v-if="filteredDashboard.length">
										<div
											class="dropdown-item"
											@click.stop="selectOptionFilterDashboard(filterItem)"
											v-bind:class="{ active: selectedFiltersDashboard[filterItem.type].includes(filterItem.id) }"
										>
											<div class="checkbox">
												<div class="checkmark"></div>
												<div class="icon">
													<icon-filter-objectives v-if="filterItem.type == 'objectives'" />
													<icon-filter-tags v-else-if="filterItem.type == 'tags'" />
													<icon-filter-roles v-else-if="filterItem.type == 'roles'" />
													<icon-filter-projects v-else-if="filterItem.type == 'projects'" />
													<icon-filter-key-results v-else-if="filterItem.type == 'key_results'" />
												</div>
												<span class="text">{{ filterItem.name }}</span>
											</div>
										</div>
									</template>
								</div>
							</div>

							<div class="filter-dropdown-header filter-activities dropdown">
								<button class="btn-tbf white types" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text" v-if="!selectedOptionsTypes.length">{{ $t("dashboard.filter_activity") }}</span>
									<span class="text" v-else-if="selectedOptionsTypes.length == 1">{{ optionsType.find((el) => el.key == selectedOptionsTypes[0]).name }}</span>
									<span class="text" v-else>{{ `${selectedOptionsTypes.length} ${$t("dashboard.types_selected")}` }}</span>

									<div class="icon-filter"><icon-arrow /></div>
								</button>
								<div class="dropdown-menu types-options" aria-labelledby="dropdownFilter">
									<template v-for="option in optionsType">
										<div class="dropdown-item" @click.stop="selectOption('selectedOptionsTypes', option.key)" v-bind:class="{ active: selectedOptionsTypes.includes(option.key) }">
											<div class="checkbox">
												<div class="checkmark"></div>
												<span class="text">{{ option.name }}</span>
											</div>
										</div>
									</template>
								</div>
							</div>

							<div class="btn-tbf blue add-task" :class="{ 'only-icon': $resize && $mq.below(1659) }" @click="showModal('choose_activity')" v-if="soft_organigram.is_active">
								<div class="text" v-if="$resize && $mq.above(1660)">{{ $t("dashboard.add_activity") }}</div>
								<div class="icon" v-else><icon-plus /></div>
							</div>
						</div>
					</div>
					<list-activities
						ref="listActivities"
						:filterJson="
							JSON.stringify({
								user: { slug: $auth.user().slug, id: $auth.user().id, right: $auth.user().right },
								sources: selectedOptionsSource,
								types: selectedOptionsTypes,
								dateRange: dateRange,
								loadedKey: loadedQueryFiltersKey,
								filters_dashboard: selectedFiltersDashboard,
							})
						"
						v-if="selectedViewType == 'list'"
					/>

					<split-calendar
						ref="splitCalendarComponent"
						:viewCalendar="timeRange"
						:filterJson="
							JSON.stringify({
								sources: selectedOptionsSource,
								loadedKey: loadedQueryFiltersKey,
								types: selectedOptionsTypes,
								filters_dashboard: selectedFiltersDashboard,
							})
						"
						v-else-if="selectedViewType == 'split'"
					/>
				</div>
			</div>
			<div class="responsibilities-row" v-else>
				<div class="header-section">
					<div class="title-section">{{ $t("dashboard.work_calendar") }}</div>
				</div>
				<div class="box-calendar-required">
					<not-module />
				</div>
			</div>

			<timeline ref="timeline" :data="{ user: $auth.user() }" v-if="soft_objectives.is_active" />

			<div class="announcements-row" v-if="soft_objectives.is_active || soft_organigram.is_active">
				<list-objectives class="announcement-item w-100" v-if="soft_objectives && soft_objectives.is_active" />
				<list-key-results class="announcement-item w-100" v-if="soft_objectives && soft_objectives.is_active" />
				<list-responsibilities class="announcement-item w-100" v-if="soft_organigram && soft_organigram.is_active" />
				<list-announcements class="announcement-item" v-if="soft_organigram && soft_organigram.is_active" />
				<list-reviews class="announcement-item" v-if="soft_organigram && soft_organigram.is_active" />
			</div>
		</div>
		<div class="space-right"></div>
	</div>
</template>

<script>
import IconLearn from "../../Icons/LearnProcedures";
import IconOrganigram from "../../Icons/OrganigramDuoTone";
import IconObjectives from "../../Icons/ObjectivesDuotone";
import IconCheckCircle from "../../Icons/CheckCircle";
import IconXCircle from "../../Icons/CircleXMark";
import IconRight from "../../Icons/Right";
import IconLeft from "../../Icons/Left";
import IconArrow from "../../Icons/Arrow";
import IconClose from "../../Icons/Close";
import IconDate from "../../Icons/Date";
import IconPlus from "../../Icons/Plus";
import IconList from "../../Icons/List";
import IconCalendar from "../../Icons/Calendar";
import IconUser from "../../Icons/User";
import IconPeople from "../../Icons/People";

import SplitCalendar from "../../Pages/Dashboard/SplitCalendar";
import ListActivities from "../../Pages/Dashboard/ListActivities";
import ListAnnouncements from "../../Pages/Dashboard/ListAnnouncements";
import ListReviews from "../../Pages/Dashboard/ListReviews";
import ListKeyResults from "../../Pages/Dashboard/ListKeyResults";
import ListResponsibilities from "../../Pages/Dashboard/ListResponsibilities";
import ListObjectives from "../../Pages/Dashboard/ListObjectives";
import Timeline from "../../Pages/Dashboard/Timeline";

import IconFilterObjectives from "../../Icons/Objectives";
import IconFilterKeyResults from "../../Icons/KeyResult";
import IconFilterTags from "../../Icons/Tags";
import IconFilterRoles from "../../Icons/Role";
import IconFilterProjects from "../../Icons/Projects";

import NotModule from "@/components/General/NotModule";

export default {
	components: {
		SplitCalendar,
		ListActivities,
		ListAnnouncements,
		ListReviews,
		ListKeyResults,
		ListObjectives,
		ListResponsibilities,
		Timeline,

		IconLearn,
		IconObjectives,
		IconOrganigram,
		IconCheckCircle,
		IconXCircle,
		IconRight,
		IconLeft,
		IconArrow,
		IconClose,
		IconDate,
		IconPlus,
		IconList,
		IconCalendar,
		IconUser,
		IconPeople,

		IconFilterObjectives,
		IconFilterKeyResults,
		IconFilterTags,
		IconFilterRoles,
		IconFilterProjects,

		NotModule,
	},
	data() {
		return {
			loaded: false,
			loadedQueryFiltersKey: 0,
			loadedApplications: false,
			loadedObjectives: false,
			loadedProcedures: false,
			hasObjectives: true,
			hasProcedures: 0,
			sections: [],
			totalSections: 0,
			taskSections: [],
			totalTaskSections: 0,
			objectives: {},
			timeRange: "weekly",
			optionsSource: [
				{ key: "personal", name: "Personale" },
				{ key: "others", name: "A colegiilor" },
			],
			optionsType: [
				{ key: "responsibilities", name: "Responsabilitati" },
				{ key: "tasks", name: "Taskuri" },
				{ key: "meetings", name: "Intalniri" },
			],
			selectedOptionsTypes: [],
			selectedOptionsSource: "",
			selectedViewType: "split",
			dateRange: {
				startDate: moment(),
				endDate: moment().add(+30, "days"),
			},
			calendarFilterSettings: {
				firstDay: 1,
				format: "DD-MM-YYYY",
				daysOfWeek: moment.weekdaysMin(),
				monthNames: moment.monthsShort(),
			},
			calendarPeriod: "",
			loadedFilters: false,
			filtersDashboard: [],
			selectedFiltersDashboard: {
				objectives: [],
				key_results: [],
				projects: [],
				tags: [],
				roles: [],
				general: [],
			},
			searchFilterDashboard: "",
			reportResponsabilities: true,
			reportTasks: {},
			donutReportTaskKey: 0,
		};
	},
	computed: {
		soft_procedures() {
			return this.$store.getters["applications/getApplication"]("Proceduri");
		},
		soft_objectives() {
			return this.$store.getters["applications/getApplication"]("Obiective");
		},
		soft_organigram() {
			return this.$store.getters["applications/getApplication"]("Organigrama");
		},
		filteredDashboard() {
			return getByKeywordFilter(this.filtersDashboard, this.searchFilterDashboard);
		},
	},
	watch: {
		dateRange: function(val) {
			this.addFilterToRouteQuery("date");
		},
		soft_procedures(newValue, oldValue) {
			if (newValue.is_active) {
				this.getProceduresData();
			}
		},
		soft_objectives(newValue, oldValue) {
			if (newValue.is_active) {
				this.getObjectivesData();
			}
		},
	},
	async mounted() {
		var refreshIntervalApps = setInterval(() => {
			if (this.$store.state.applications.applications.length) {
				clearInterval(refreshIntervalApps);
				this.loadedApplications = true;
			}
		}, 50);

		setTimeout(() => {
			$(".opacity-page").addClass("show");
			this.generateGreetings();
		}, 0);

		if (!this.$route.query.types) {
			this.selectedOptionsTypes = this.optionsType.map((el) => {
				return el.key;
			});
		}

		await this.getFilters();
		await this.checkQueryFilters();
		await this.getDashboardData();
		await this.getTaskReport();

		if (this.soft_procedures && this.soft_procedures.is_active) {
			this.getProceduresData();
		}

		if (this.soft_objectives && this.soft_objectives.is_active) {
			this.getObjectivesData();
		}

		this.$root.$on("refresh_objective", () => {
			this.getObjectivesData();
		});

		this.$root.$on("refresh_Procedures_Tracking", () => {
			this.getProceduresData();
		});

		this.$root.$on("refreshTaskReport", () => {
			this.getTaskReport();
		});

		// this.$root.$on('refresh_daily_responsabilities', () => {
		// 	this.getDashboardData()
		// });
	},
	methods: {
		async getFilters() {
			this.filtersDashboard = [];

			axios
				.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/instances/${this.$auth.user().master_instance_id}/filter/external`, {
					params: {
						search_structure: true,
					},
				})
				.then(({ data }) => {
					this.filtersDashboard.push(...data.data.search_structure);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					this.loadedFilters = true;
				});

			if (this.soft_organigram.is_active) {
				axios
					.get(`instances/${this.$auth.user().instance.id}/filter`, {
						params: {
							search_structure: true,
						},
					})
					.then(({ data }) => {
						this.filtersDashboard.push(...data.data.search_structure);
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.status == 500) {
								alert(this.$t("error.500"));
							}
						}
					})
					.finally(() => {
						this.loadedFilters = true;
					});
			}
		},
		generateGreetings() {
			var title = this.$t("general.hello_user", { name: this.$auth.user().first_name });

			this.$root.$emit("navbar_title", title);
		},
		async getDashboardData() {
			await axios
				.get(`/dashboard`)
				.then(({ data }) => {
					this.responsibilities = data.data;
					this.sections = [
						{
							label: "Realizate",
							value: this.responsibilities.last_month_responsibilities.accomplished,
							color: "#03ae58",
						},
						{
							label: "Nerealizate",
							value: this.responsibilities.last_month_responsibilities.unaccomplished,
							color: "#fe5d35",
						},
						{
							label: "Nerealizate din motive speciale",
							value: this.responsibilities.last_month_responsibilities.free_day,
							color: "#ffcd56",
						},
						{
							label: "Neevaluate",
							value: this.responsibilities.last_month_responsibilities.unevaluated,
							color: "#45a0ff",
						},
					];
					this.totalSections =
						this.responsibilities.last_month_responsibilities.accomplished +
						this.responsibilities.last_month_responsibilities.unaccomplished +
						this.responsibilities.last_month_responsibilities.free_day +
						this.responsibilities.last_month_responsibilities.unevaluated;
					if (this.totalSections == 0) {
						this.totalSections = 1;
					}
				})
				.finally(() => {
					this.loaderDailyResp = true;
					this.loaded = true;
				});
		},
		getProceduresData() {
			axios
				.get(`${process.env.VUE_APP_PROCEDURES_LINK}/api/${this.$auth.user().slug}/procedure-checks`, { params: { from_organigram: true } })
				.then(({ data }) => {
					this.hasProcedures = data.data;
				})
				.finally(() => {
					this.loadedProcedures = true;
				});
		},
		getObjectivesData() {
			axios
				.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/dashboard-for-organigram`)
				.then(({ data }) => {
					this.objectives = data.data;
				})
				.finally(() => {
					this.loadedObjectives = true;
				});
		},
		showModal(type, data = false, itemId = false) {
			this.$root.$emit("open_modal", type, data, itemId);
		},
		selectOptionFilterDashboard(value) {
			if (this.selectedFiltersDashboard.general.includes(`${value.type}-${value.id}`)) {
				this.selectedFiltersDashboard.general.splice(this.selectedFiltersDashboard.general.indexOf(`${value.type}-${value.id}`), 1);
			} else {
				this.selectedFiltersDashboard.general.push(`${value.type}-${value.id}`);
			}

			if (this.selectedFiltersDashboard[value.type].includes(parseInt(value.id))) {
				this.selectedFiltersDashboard[value.type].splice(this.selectedFiltersDashboard[value.type].indexOf(parseInt(value.id)), 1);
			} else {
				this.selectedFiltersDashboard[value.type].push(parseInt(value.id));
			}

			this.addFilterToRouteQuery(`filterDashboard${value.type.charAt(0).toUpperCase() + value.type.slice(1)}`);
		},
		selectOption(variable, value) {
			if (typeof value == "string") {
				if (this[variable].includes(value)) {
					this[variable].splice(this[variable].indexOf(value), 1);
				} else {
					this[variable].push(value);
				}
			} else {
				if (this[variable].includes(parseInt(value))) {
					this[variable].splice(this[variable].indexOf(parseInt(value)), 1);
				} else {
					this[variable].push(parseInt(value));
				}
			}

			this.addFilterToRouteQuery(variable);
		},
		clearFilter(filter) {
			if (filter == "selectedFiltersDashboard") {
				this.selectedFiltersDashboard = {
					objectives: [],
					key_results: [],
					projects: [],
					tags: [],
					roles: [],
					general: [],
				};
			} else {
				this[filter] = [];
			}

			this.addFilterToRouteQuery(filter);
		},
		prevPeriod() {
			if (this.selectedViewType == "split") {
				this.$refs.splitCalendarComponent.$refs.calendarComponent.prevPeriodApi();
				this.intervalCalendar(this.$refs.splitCalendarComponent.$refs.calendarComponent.$refs.fullCalendar.getApi().currentData.viewTitle);
			}
		},
		nextPeriod() {
			if (this.selectedViewType == "split") {
				this.$refs.splitCalendarComponent.$refs.calendarComponent.nextPeriodApi();
				this.intervalCalendar(this.$refs.splitCalendarComponent.$refs.calendarComponent.$refs.fullCalendar.getApi().currentData.viewTitle);
			}
		},
		goToToday() {
			if (this.selectedViewType == "split") {
				this.$refs.splitCalendarComponent.$refs.calendarComponent.goToTodayApi();
				this.intervalCalendar(this.$refs.splitCalendarComponent.$refs.calendarComponent.$refs.fullCalendar.getApi().currentData.viewTitle);
			}
		},
		changeView(type) {
			this.timeRange = type;
			switch (type) {
				case "monthly":
					// this.$refs.calendarComponent.changeViewApi('dayGridMonth');
					break;
				case "weekly":
					// this.$refs.calendarComponent.changeViewApi('timeGridWeek');
					break;
				case "daily":
					// this.$refs.calendarComponent.changeViewApi('listWeek');
					break;
			}

			this.addFilterToRouteQuery("timeRange");
		},
		addFilterToRouteQuery(filterName) {
			var dataQuery = Object.assign({}, this.$route.query);

			if (filterName == "selectedViewType") {
				dataQuery["view"] = this.selectedViewType;
			}
			if (filterName == "selectedOptionsSource") {
				dataQuery["source"] = this.selectedOptionsSource;
			}
			if (filterName == "selectedOptionsTypes") {
				dataQuery["types"] = this.selectedOptionsTypes.toString();
			}

			//Filters Dashboard
			if (filterName == "filterDashboardObjectives") {
				dataQuery["objectives"] = this.selectedFiltersDashboard.objectives.toString();
			}
			if (filterName == "filterDashboardKey_results") {
				dataQuery["key_results"] = this.selectedFiltersDashboard.key_results.toString();
			}
			if (filterName == "filterDashboardTags") {
				dataQuery["tags"] = this.selectedFiltersDashboard.tags.toString();
			}
			if (filterName == "filterDashboardRoles") {
				dataQuery["roles"] = this.selectedFiltersDashboard.roles.toString();
			}
			if (filterName == "filterDashboardProjects") {
				dataQuery["projects"] = this.selectedFiltersDashboard.projects.toString();
			}

			if (filterName == "timeRange") {
				dataQuery["view_calendar"] = this.timeRange;
			}

			if (filterName == "date") {
				dataQuery["start_date"] = moment(this.dateRange.startDate).format("DD-MM-YYYY");
				dataQuery["end_date"] = moment(this.dateRange.endDate).format("DD-MM-YYYY");
			}

			Object.keys(dataQuery).map(function(key, value) {
				if (dataQuery[key] == "") {
					delete dataQuery[key];
				}
			});

			this.$router.push({ query: dataQuery });

			if (filterName != "timeRange") {
				this.loadedQueryFiltersKey++;
			}
		},
		checkQueryFilters() {
			var dataQuery = Object.assign({}, this.$route.query);

			Object.keys(dataQuery).forEach((key) => {
				if (key == "view") {
					this.selectedViewType = dataQuery[key];
				}
				if (key == "source") {
					this.selectedOptionsSource = dataQuery[key];
				}
				if (key == "types") {
					this.selectedOptionsTypes = dataQuery[key].split(",");
				}
				if (key == "start_date") {
					this.dateRange.startDate = moment(dataQuery[key], "DD-MM-YYYY");
				}
				if (key == "end_date") {
					this.dateRange.endDate = moment(dataQuery[key], "DD-MM-YYYY");
				}

				if (key == "objectives") {
					this.selectedFiltersDashboard.objectives = dataQuery[key].split(",").map(Number);
				}
				if (key == "key_results") {
					this.selectedFiltersDashboard.key_results = dataQuery[key].split(",").map(Number);
				}
				if (key == "tags") {
					this.selectedFiltersDashboard.tags = dataQuery[key].split(",").map(Number);
				}
				if (key == "roles") {
					this.selectedFiltersDashboard.roles = dataQuery[key].split(",").map(Number);
				}
				if (key == "projects") {
					this.selectedFiltersDashboard.projects = dataQuery[key].split(",").map(Number);
				}

				if (key == "open_task") {
					this.$root.$emit("open_modal", "add_task", {
						crud: "edit",
						taskEditId: parseInt(dataQuery[key]),
						// type: this.$route.params.log_type,
						date: dataQuery["date"],
					});
				}

				if (key == "open_meeting") {
					this.$root.$emit("open_modal", "add_meeting_view_only", {
						crud: "edit",
						meetingEditId: parseInt(dataQuery[key]),
						type: this.$route.params.log_type,
						date: this.$route.params.date,
					});
				}

				if (key == "view_calendar") {
					this.timeRange = dataQuery[key];
				}
			});

			this.loadedQueryFiltersKey++;
		},
		queryObject(paramsCall) {
			var dataQuery = Object.assign({}, this.$route.query);

			Object.keys(dataQuery).forEach((key) => {
				if (key == "view") {
					paramsCall.assignation = dataQuery[key];
				}
				if (key == "source") {
					paramsCall.assignation = dataQuery[key];
				}
				if (key == "types") {
					paramsCall.log_types = dataQuery[key].split(",");
				}
				if (key == "start_date") {
					this.dateRange.startDate = moment(dataQuery[key], "DD-MM-YYYY");
				}
				if (key == "end_date") {
					this.dateRange.endDate = moment(dataQuery[key], "DD-MM-YYYY");
				}

				if (key == "objectives") {
					paramsCall.objectives_ids = dataQuery[key].split(",");
				}
				if (key == "key_results") {
					paramsCall.key_results_ids = dataQuery[key].split(",");
				}
				if (key == "tags") {
					paramsCall.tags_ids = dataQuery[key].split(",");
				}
				if (key == "roles") {
					paramsCall.roles_ids = dataQuery[key].split(",");
				}
				if (key == "projects") {
					paramsCall.projects_ids = dataQuery[key].split(",");
				}
			});

			return paramsCall;
		},
		changeListType(type) {
			switch (type) {
				case "list":
					this.timeRange = "daily";
					this.selectedViewType = "list";
					this.addFilterToRouteQuery("selectedViewType");
					break;
				case "split":
					this.timeRange = "weekly";
					this.selectedViewType = "split";
					this.addFilterToRouteQuery("selectedViewType");
					this.addFilterToRouteQuery("timeRange");
					break;
				case "personal":
					this.selectedOptionsSource = "personal";
					this.addFilterToRouteQuery("selectedOptionsSource");
					this.getTaskReport();
					break;
				case "all":
					this.selectedOptionsSource = "";
					this.addFilterToRouteQuery("selectedOptionsSource");
					this.getTaskReport();
					break;
			}
		},
		intervalCalendar(value) {
			this.calendarPeriod = value;
		},
		openModalSubscription() {
			if (this.$auth.user().instance.global_stripe === 1) {
				this.$root.$emit("open_modal", "change_subscription");
			} else {
				window.location.replace(`${process.env.VUE_APP_CENTRAL_FE_LINK}/price`);
			}
		},
		async getTaskReport() {
			var paramsCall = {};

			if (this.selectedOptionsSource == "personal") {
				paramsCall.assignation = this.selectedOptionsSource;
			}

			await axios.get(`/${this.$auth.user().slug}/logs-list-section`, { params: paramsCall }).then(({ data }) => {
				this.reportTasks = data.data.report;

				this.taskSections = [
					{
						label: "Deschise",
						value: this.reportTasks.future_unaccomplished,
						color: "#ffcd56",
					},
					{
						label: "Termen depasit",
						value: this.reportTasks.past_unaccomplished,
						color: "#fe5d35",
					},
					{
						label: "Realizate in termen",
						value: this.reportTasks.accomplished_before_deadline,
						color: "#03ae58",
					},
					{
						label: "Realizate cu intarziere",
						value: this.reportTasks.accomplished_after_deadline,
						color: "#45a0ff",
					},
					{
						label: "Fara termen",
						value: this.reportTasks.without_deadline,
						color: "#dcdee1",
					},
				];

				this.totalTaskSections =
					this.reportTasks.future_unaccomplished +
					this.reportTasks.past_unaccomplished +
					this.reportTasks.accomplished_before_deadline +
					this.reportTasks.accomplished_after_deadline +
					this.reportTasks.without_deadline;

				this.donutReportTaskKey++;
			});
		},
	},
};

function getByKeywordFilter(list, keyword) {
	const search = keyword.trim();

	if (!search.length) return list;
	return list.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
}
</script>
